<template>
  <div class="container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal
      ref="modal_confirm_cancel"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="redirectBack">
    </ConfirmModal>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off">
        <div class="control-label input-label justify-content-left" style="margin-left:8px; padding-top: 40px" v-if="furusato_product.master_code">
          {{$t('furusato_product.edit')}}
        </div>
        <div class="control-label input-label justify-content-left" style="margin-left:8px; padding-top: 40px" v-else>
          {{$t('nav.furusato_product_create')}}
        </div>

        <div class="row" style="margin-top:20px;">
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="string"
              name="master_code"
              :label="$t('furusato_product.master_code')"
              input-style="normal"
              rules="max:191"
              v-model="furusato_product.master_code"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 m-b-10">
            <AppSelect
              :label="$t('furusato_product.furusato_city_id')"
              input-style="normal"
              name="full_city_id"
              boundaryClass="m-auto"
              rules="required"
              :options="{containerCssClass: 'z-index-0'}"
              :options-data="options_cities"
              :value_empty="false"
              v-model="furusato_product.full_city_id"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 m-b-10">
            <AppSelect
              :label="$t('furusato_product.furusato_office_id')"
              input-style="normal"
              name="office_id"
              boundaryClass="m-auto"
              :options="{containerCssClass: 'z-index-0'}"
              :options-data="meta.offices"
              :value_empty="true"
              v-model="furusato_product.office_id"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="string"
              name="name"
              :label="$t('furusato_product.name')"
              input-style="normal"
              rules="required|max:191"
              v-model="furusato_product.name"
            />
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <div class="col-md-12 p-0">
              <label class="control-label input-label p-b-10"
                     style="margin-top:15px;">{{
                $t('furusato_product.category') }}</label>
            </div>
            <AppCheckbox
              style="padding-right: 20px"
              name="category"
              :label="$t('furusato_product.category_sleep')"
              v-model="furusato_product.type_sleep">
            </AppCheckbox>
            <AppCheckbox
              style="padding-right: 20px"
              name="category"
              :label="$t('furusato_product.category_food')"
              v-model="furusato_product.type_food">
            </AppCheckbox>
            <AppCheckbox
              style="padding-right: 20px"
              name="category"
              :label="$t('furusato_product.category_experience')"
              v-model="furusato_product.type_experience">
            </AppCheckbox>
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="bigint"
              name="price"
              :label="$t('furusato_product.price')"
              input-style="normal"
              rules="min_value:0|max_value:4294967295|required"
              v-model="furusato_product.price"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="bigint"
              name="stocks"
              :label="$t('furusato_product.stocks')"
              input-style="normal"
              rules="min_value:0|max_value:4294967295|required"
              v-model="furusato_product.stocks"
            />
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 row">
            <div class="col-md-12 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.attendee') }}</label>
            </div>
            <div class="col-md-1 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.limit_of_attendee_adults') }}</label>
            </div>
            <div class="col-md-3">
              <AppInput name="limit_of_attendee_adults" input-style="normal" type="bigint"
                        rules="min_value:0|max_value:99" v-model="furusato_product.limit_of_attendee_adults">
              </AppInput>
            </div>
            <div class="col-md-1">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.limit_of_attendee_children') }}</label>
            </div>
            <div class="col-md-3 p-0">
              <AppInput name="limit_of_attendee_children" input-style="normal" type="bigint"
                        rules="min_value:0|max_value:99" v-model="furusato_product.limit_of_attendee_children">
              </AppInput>
            </div>
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 col-lg-6 row p-b-20">
            <div class="col-md-12 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.reception_date') }}</label>
              <span class="color-active app-text-bold"> ＊</span>
            </div>
            <div class="col-md-1 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.start_date') }}</label>
            </div>
            <div class="col-md-2">
              <AppSelect
                input-style="normal"
                name="publish_at_mm"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_months"
                :value_empty="false"
                rules="required"
                v-model="furusato_product.publish_at_mm"
                @change="getPublishDays(furusato_product.publish_at_mm)"
              />
            </div>
            <label class="input-label label-datetime-margin">月</label>
            <div class="col-md-2 p-0">
              <AppSelect
                v-model="furusato_product.publish_at_dd"
                input-style="normal"
                name="publish_at_dd"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_publish_days"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">日</label>
            <div class="col-md-2">
              <AppSelect
                input-style="normal"
                name="publish_at_hour"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_hours"
                :value_empty="false"
                rules="required"
                v-model="furusato_product.publish_at_hour"
              />
            </div>
            <label class="input-label label-datetime-margin">:</label>
            <div class="col-md-2">
              <AppSelect
                v-model="furusato_product.publish_at_minute"
                input-style="normal"
                name="publish_at_minute"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_minutes"
                :value_empty="false"
                rules="required"
              />
            </div>
          </div>

          <div class="col-md-12"></div>
          <div class="col-md-12" style="margin-bottom:10px"></div>
          <div class="col-md-12 col-lg-6 row">
            <div class="col-md-1 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_product.end_date') }}</label>
            </div>
            <div class="col-md-2">
              <AppSelect
                v-model="furusato_product.expire_at_mm"
                input-style="normal"
                name="expire_at_mm"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_months"
                :value_empty="false"
                rules="required"
                @change="getExpireDays(furusato_product.expire_at_mm)"
              />
            </div>
            <label class="input-label label-datetime-margin">月</label>
            <div class="col-md-2 p-0">
              <AppSelect
                v-model="furusato_product.expire_at_dd"
                input-style="normal"
                name="expire_at_dd"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_expire_days"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">日</label>
            <div class="col-md-2">
              <AppSelect
                v-model="furusato_product.expire_at_hour"
                input-style="normal"
                name="expire_at_hour"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_hours"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">:</label>
            <div class="col-md-2">
              <AppSelect
                v-model="furusato_product.expire_at_minute"
                input-style="normal"
                name="expire_at_minute"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_minutes"
                :value_empty="false"
                rules="required"
              />
            </div>
<!--            <div class="col-md-6 p-b-20">-->
<!--              <label class="error app-input-error-label-2" v-if="time_publish > time_expire">-->
<!--                開始日は終了日より前かと同じ日付になります。-->
<!--              </label>-->
<!--              <label class="error app-input-error-label-2" v-if="time_expire < new Date().valueOf()">-->
<!--                終了日は今日より後の日付を入力してください。-->
<!--              </label>-->
              <label class="error app-input-error-label-2" v-if="(time_publish && publishDateIsValid == false) ||
               (time_expire && expireDateIsValid == false)">
                正しい日付を選択してください。
              </label>
<!--            </div>-->
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6">
            <AppText
              type="text"
              name="description"
              input-style="normal"
              :label="$t('furusato_product.description')"
              aria-valuemax="10000"
              v-model="furusato_product.description"
              rules="max:10000"/>
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6">
            <div class="col-md-6">
              <label class="input-label control-label p-t-10">
                {{ $t('furusato_product.furusato_image') }}
              </label>
              <span class="color-active app-text-bold">＊</span>
            </div>

            <div class="col-md-6" style="margin-bottom:10px"></div>
            <div class="col-md-12">
              <div class="row">
                <template v-for="(image) in furusato_product.furusato_images">
                  <div class="col-md-3 text-center mb-3">
                    <img
                      style="width: 150px; height: 150px"
                      v-if="image"
                      :src="image.image_url || image"
                    />
                    <div
                      v-if="image"
                      class="btn-tool delete remove-image ml-0 mt-3"
                      @click="removeImage(image)">
                    </div>
                  </div>
                </template>
              </div>
            <div class="col-md-6" style="margin-bottom:10px"></div>
              <input
                ref="furusato_image"
                class="col-md-12 p-t-15"
                accept="image/jpg,image/jpeg,image/png,image/gif"
                type="file"
                multiple
                rules="required"
                @change="uploadImage($event)"
              />
            </div>
            <label class="error app-input-error-label-2"
                   v-if="furusato_product.furusato_images.length == 0 && submit == true">
              こちらは必須項目です。
            </label>
          </div>

        </div>
        <div class="m-t-10 row justify-content-end btn-spacing-bottom">
          <button type="button"
                  class="btn btn-default m-r-10" @click="confirmCancel">
            {{ $t('common.cancel') }}
          </button>
          <button :disabled="disableCondition"
                  class="btn btn-complete m-r-10" type="button" @click="saveFurusatoProduct($consts.INPUT.TYPE_PUBLIC)">
            {{ $t('common.public_btn') }}
          </button>
          <button :disabled="disableCondition"
                  class="btn btn-complete m-r-10" type="button" @click="saveFurusatoProduct($consts.INPUT.TYPE_DRAFT)">
            {{ $t('common.draft_btn') }}
          </button>
        </div>
        <div class="col-md-12 col-lg-12">&nbsp;</div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import ROUTES from "@consts/routes";
  import AppCheckbox from "../../_common/forms/AppCheckbox";
  import {AppDateTime} from '../../../services/utils';
  import {cloneDeep} from "lodash";

  export default {
    components: {
      AppCheckbox
    },
    data() {
      const image_default = {
        height: 300,
        width: 400
      }
      const image_default_square = {
        height: 550,
        width: 550
      }
      const id = this.$route.params.id
      return {
        furusato_product: {
          id: "",
          full_city_id: [],
          office_id: [],
          status: "",
          master_code: "",
          name: "",
          price: "",
          description: "",
          type_sleep: 0,
          type_food: 0,
          type_experience: 0,
          stocks: "",
          limit_of_attendee_adults: "",
          limit_of_attendee_children: "",
          publish_at_mm: "",
          publish_at_dd: "",
          publish_at_hour: "",
          publish_at_minute: "",
          expire_at_mm: "",
          expire_at_dd: "",
          expire_at_hour: "",
          expire_at_minute: "",
          furusato_images: [],
          images: []
        },
        delete: {
          name: "",
          key_1: "",
          key_2: ""
        },
        validate:"",
        submit: false,
        freezeObj: {},
        firstLoading: true,
        options_cities: [],
        options_offices: [],
        options_months: [],
        options_publish_days: [],
        options_expire_days: [],
        options_hours: [],
        options_minutes: [],
        image_default: _.cloneDeep(image_default),
        image_default_square: _.cloneDeep(image_default_square),
        image:{
          height:'',
          width:''
        },
        furusatoImage:_.cloneDeep(image_default),
        images: [],
        idEditing: id,
        meta: {
          offices: [],
        },
      }
    },
    computed: {
      time_publish() {
        let year = new Date().getFullYear()
        return moment(year + '/' + this.furusato_product.publish_at_mm + '/' + this.furusato_product.publish_at_dd + ' ' +
          this.furusato_product.publish_at_hour + ':' + this.furusato_product.publish_at_minute).valueOf();
      },
      time_expire() {
        let year = new Date().getFullYear()
        return moment(year + '/' + this.furusato_product.expire_at_mm + '/' + this.furusato_product.expire_at_dd + ' ' +
          this.furusato_product.expire_at_hour + ':' + this.furusato_product.expire_at_minute).valueOf();
      },
      publishDateIsValid() {
        let year = new Date().getFullYear()
        let publishDate = moment(year + '/' + (this.furusato_product.publish_at_mm > 9 ? this.furusato_product.publish_at_mm :
          '0' + this.furusato_product.publish_at_mm) + '/' + (this.furusato_product.publish_at_dd > 9 ? this.furusato_product.publish_at_dd :
            '0' + this.furusato_product.publish_at_dd), "YYYY/MM/DD", true)
        return publishDate.isValid();
      },
      expireDateIsValid() {
        let year = new Date().getFullYear()
        let expireDate = moment(year + '/' + (this.furusato_product.expire_at_mm > 9 ? this.furusato_product.expire_at_mm :
          '0' + this.furusato_product.expire_at_mm) + '/' + (this.furusato_product.expire_at_dd > 9 ? this.furusato_product.expire_at_dd :
            '0' + this.furusato_product.expire_at_dd), "YYYY/MM/DD", true)
        return expireDate.isValid();
      },
      disableCondition(){
        return (
          (!this.valid && this.validated)
          || (this.furusato_product.furusato_images.length == 0 && this.submit == true)
          // || (this.time_publish > this.time_expire && this.submit == true)
          // || (this.time_expire < new Date().valueOf() && this.submit == true)
          || ((this.publishDateIsValid == false || this.expireDateIsValid == false) && this.submit == true))
      }
    },
    async created() {
      await this.getFullCities()
      this.options_months = await AppDateTime.getMonths()
      await this.getPublishDays(this.furusato_product.publish_at_dd)
      await this.getExpireDays()
      this.options_hours = await AppDateTime.getHours()
      this.options_minutes = await AppDateTime.getMinutes()
    },
    watch: {
      'furusato_product.full_city_id': function(value, oldValue) {
        if (value != oldValue && value != '') {
          this.getOfficeList(value)
        }
      }
    },
    mounted: function () {
      let params = this.$route.params;

      if (params.id) {
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_PRODUCT_EDIT(params.id)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error') {
              this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
            }
          } else {
            this.furusato_product = res.data.data
            this.furusato_product.furusato_images = res.data.data.image
            let year = new Date().getFullYear()
            let publish_at = new Date(year + '-' + this.furusato_product.publish_at_mm + '-' + this.furusato_product.publish_at_dd + ' ' +
              this.furusato_product.publish_at_time)
            let expire_at = new Date(year + '-' + this.furusato_product.expire_at_mm + '-' + this.furusato_product.expire_at_dd + ' ' +
              this.furusato_product.expire_at_time)
            this.furusato_product.publish_at_mm = publish_at.getMonth() + 1
            this.furusato_product.publish_at_dd = publish_at.getDate()
            this.furusato_product.publish_at_hour = publish_at.getHours() <= 9 ? '0' + publish_at.getHours() : publish_at.getHours()
            this.furusato_product.publish_at_minute = publish_at.getMinutes() <= 9 ? '0' + publish_at.getMinutes() : publish_at.getMinutes()

            this.furusato_product.expire_at_mm = expire_at.getMonth() + 1
            this.furusato_product.expire_at_dd = expire_at.getDate()
            this.furusato_product.expire_at_hour = expire_at.getHours() <= 9 ? '0' + expire_at.getHours() : expire_at.getHours()
            this.furusato_product.expire_at_minute = expire_at.getMinutes() <= 9 ? '0' + expire_at.getMinutes() : expire_at.getMinutes()
          }
        })
      }
    },
    methods: {
      async saveFurusatoProduct(type) {
        this.submit = true;
        if (!await this.$refs.form_create_entry.validate() || this.furusato_product.furusato_images.length == 0 ||
          // (this.time_publish > this.time_expire) || (this.time_expire < new Date().valueOf()) ||
            (this.publishDateIsValid == false || this.expireDateIsValid == false)) {
          return;
        }

        let saveFurusatoProductData = {
          master_code: this.furusato_product.master_code ? this.furusato_product.master_code.trim() : null,
          full_city_id: this.furusato_product.full_city_id,
          office_id: this.furusato_product.office_id,
          name: this.furusato_product.name,
          type_sleep: this.furusato_product.type_sleep == 1 ? true : false,
          type_food: this.furusato_product.type_food == 1 ? true : false,
          type_experience: this.furusato_product.type_experience == 1 ? true : false,
          price: this.furusato_product.price,
          stocks: this.furusato_product.stocks,
          limit_of_attendee_adults: this.furusato_product.limit_of_attendee_adults,
          limit_of_attendee_children: this.furusato_product.limit_of_attendee_children,
          publish_at_mm: this.furusato_product.publish_at_mm,
          publish_at_dd: this.furusato_product.publish_at_dd,
          publish_at_time: this.furusato_product.publish_at_hour + this.furusato_product.publish_at_minute,
          expire_at_mm: this.furusato_product.expire_at_mm,
          expire_at_dd: this.furusato_product.expire_at_dd,
          expire_at_time: this.furusato_product.expire_at_hour + this.furusato_product.expire_at_minute,
          description: this.furusato_product.description,
          furusato_image: this.furusato_product.furusato_images,
          status: type
        };
        if (this.$route.params.id) {
          saveFurusatoProductData.id = this.$route.params.id
          const res = await this.$request.patch(this.$consts.API.ADMIN.FURUSATO_PRODUCT_EDIT(this.$route.params.id), saveFurusatoProductData);
          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg update ok'));
          }
        } else {
          const res = await this.$request.post(this.$consts.API.ADMIN.FURUSATO_PRODUCT_SAVE, saveFurusatoProductData);
          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg create ok'));
            await this.$router.push({name: ROUTES.ADMIN.FURUSATO_PRODUCT_UPDATE, params: {id: res.data.data.id}} );
          }
        }
      },
      uploadImage(event) {
        const image = event.target.files;

        for(let i = 0; i < image.length; i++) {

          if (this.checkImage(image[i])) {
            this.$appNotice.error(this.$t('dialog.image_type'));
            this.$refs.furusato_image.value = null;

            return;
          }

          if ((image[i].size / 1048576) > 5) {
            this.$refs.furusato_image.value = null;
            this.$appNotice.error(this.$t('dialog.image_max', {size: '5MB'}));

            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(image[i]);

          reader.onload = e =>{
            this.uploadImage1(e.target.result, i)
            let img = new Image();
            img.onload = () => {
              this.image.width = img.width;
              this.image.height = img.height;
              this.changeSize(img.width, img.height)
            }
            img.src = e.target.result;
          };
        }
      },
      async changeSize(width, height) {
        this.furusatoImage = _.cloneDeep(this.image_default)
        if (width === height) {
          this.furusatoImage = _.cloneDeep(this.image_default_square)
        }
      },
      async uploadImage1(file, index) {
        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE_PRODUCT, {
          image: file,
          path: "furusato/products/",
          index: index
        });

        if (this.furusato_product.furusato_images.length <= 10) {
          this.furusato_product.furusato_images.push(res.data.data);
        } else {
          this.$appNotice.error(this.$t('furusato_coupon.message_error_upload_max_file'));
        }
      },
      confirmCancel() {
        this.$refs.modal_confirm_cancel.show();
      },
      redirectBack() {
        if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.ADMIN.FURUSATO_PRODUCT) {
          this.$router.go(-1);
        } else {
          this.$router.pushByName(this.$consts.ROUTES.ADMIN.FURUSATO_PRODUCT);
        }
      },
      removeImage(image){
        if (image.id) {
          this.furusato_product.furusato_images = this.furusato_product.furusato_images.filter(function(item) {
            return item.id !== image.id
          })
        } else {
          this.furusato_product.furusato_images = this.furusato_product.furusato_images.filter(function(item) {
            return item !== image
          })
        }
      },
      async getFullCities() {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, {});
        if (!res.hasErrors()) {
          this.options_cities = res.data.data;
        }
      },
      getPublishDays(month) {
        let days = new Date(new Date().getFullYear(), month, 0).getDate()
        this.options_publish_days = []
        for(let i = 1 ; i <= days ; i++) {
          this.options_publish_days.push({"id": i, "name": i})
        }
      },
      getExpireDays(month) {
        let days = new Date(new Date().getFullYear(), month, 0).getDate()
        this.options_expire_days = []
        for(let i = 1 ; i <= days ; i++) {
          this.options_expire_days.push({"id": i, "name": i})
        }
      },
      checkImageSize() {
        this.checkSize(this.furusato_product.furusato_image)
      },
      checkSize(source) {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.changeSize(img.width, img.height)
        }
        img.src = source;
      },
      async getOfficeList(full_city_id) {
        if(full_city_id) {
          await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_OFFICE_LIST(full_city_id)).then(res => {
            let offices = res.data.offices;
            if(offices.length !== 0) {
              this.meta.offices = cloneDeep(res.data.offices);
            } else {
              this.meta.offices = res.data.offices;
              this.meta.offices.unshift({
                id: "",
                name: ""
              })
            }
          })
        }
      },
    }
  }
</script>

<style scoped>
  .modal-confirm {
    z-index: 9999 !important;
  }
  .tab-content {
    border: 1px solid grey;
    border-top: 0;
  }
  .btn-spacing-bottom {
    padding-bottom: 50px;
  }

  .label-datetime-margin {
    margin: 3% 3% 0 3% !important;
  }

</style>
